<template>
  <main>
    <ul class="car-ul">
      <ui-checkbox-group
        @on-change="changeBoxGroup"
        v-model:value.sync="checked1"
        v-if="list && list.length > 0"
      >
        <li class="car-li" v-for="(item, index) in list" :key="index">
          <div class="product-wapper">
            <ui-checkbox
              :label="item.pidAndLotId"
              size="large"
            >
              <span style="font-size: 0">{{ item.pidAndLotId }}</span>
            </ui-checkbox>
            <div class="product-pic">
              <router-link
                :to="{
                  name:'productdetail',
                  params: { gid: item.gid},
                }"
              >
                <img :src="item.pic" alt="" />
              </router-link>
            </div>
            <div class="product-info">
              <router-link
                :to="{
                  name:'productdetail',
                  params: { gid: item.gid},
                }"
                class="product-name"
              >
                {{ item.title }}
              </router-link>
              <div class="product-no">WY{{ item.pid }}</div>
              <div class="product-no">{{ item.spec }}<span v-if="item.spec&&item.nongdu">,</span> {{item.nongdu}}</div>
              <div class="poroduct-num">
                <span class="sub" @click="onSub(item.pidAndLotId, index)"></span>
                <div class="input">
                  <input
                    type="number"
                    min="1"
                    name="num"
                    step="1"
                    @change="changeEdit(item.pidAndLotId, item.qty, index)"
                    v-model.number="item.qty"
                  />
                </div>
                <span class="add" @click="onAdd(item.pidAndLotId, index)"></span>
<!--                <span  class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && item.price>0">${{Number(item.price).toFixed(2)}}</span>-->
              </div>
            </div>
          </div>
        </li>

        <li class="car-li">
          <div class="product-wapper">
            <ui-checkbox
              label="22"
              size="large"
              style="opacity: 0;"
            >
              <span style="font-size: 0"></span>
            </ui-checkbox>
            <div class="product-pic">
              <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/571537662b085738c3a7e783bb0dd51e.png" alt="" />
            </div>
            <div class="product-info">
              <div class="product-name">Packaging & Freight</div>
              <div class="product-no">Free Shipping Over ${{postageFee}}</div>
            </div>
          </div>
        </li>

      </ui-checkbox-group>
    </ul>

    <div class="dashboard">
      <div class="dashboard-checkbox">
        <ui-checkbox
          label="select all"
          v-model="checked"
          @on-change="changeBox"
          size="large"
        >
        </ui-checkbox>

        <div class="del" @click="onDelete">remove</div>
      </div>
      <div @click="onSubmit" class="pay">
        <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
          submit( <span>${{ Number(totalPrice).toFixed(2) }}</span> )
        </template>
        <template v-else><span style="padding: 0 10px;">submit</span></template>
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-if="loading"></loading>
  <empty text="cart" :showLive="false" v-if="list && !list.length"></empty>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch, inject } from "vue";
import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
import UiCheckbox from "@/components/UI/Checkbox";

import axios from "@/api/axios";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast";
import useShopCar from "@/hooks/useShopCar";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import Empty from "@/components/common/Empty.vue";
import { getCartInfo } from "@/api/useChangeShopCar";
export default defineComponent({
  name: "ShopCar",
  components: {
    UiCheckbox,
    UiCheckboxGroup,
    Loading,
    Empty,
  },
  setup() {
    const biaowuPriceOpen = ref()
    const postageFee = ref()
    const delivery = ref(1)
    const list = ref<[] | null>(null);

    const checked = ref(true);
    const checked1 = reactive<any>([]);
    const loading = ref(true);
    const totalPrice = ref(0)
    const userInfo = inject("userInfo") as any;
    const changeBox = (val: any) => {
      let allPrice = 0
      if (val && list.value) {
        checked1.length = 0;
        list.value.forEach((item: any) => {
          checked1.push(item.pidAndLotId);
          allPrice += item.qty * item.price
        });
        totalPrice.value = allPrice
      } else {
        checked1.length = 0;
        totalPrice.value = 0
      }
    };
    const map = new Map();
    const count = ref(0);
    const changeBoxGroup = (val: any) => {
      let singPrice = 0
      if (val && list.value) {
        list.value.forEach((item: any) => {
          val.forEach((ele: any) => {
            if(item.pidAndLotId == ele) {
              singPrice += item.qty * item.price
            }
          });
        });
        totalPrice.value = singPrice
      }

      if (
        list.value &&
        list.value.length &&
        val.length == list.value.length
      ) {
        checked.value = true;
      } else {
        checked.value = false;
      }

      let newCount = 0;
      val.forEach((item: any) => {
        const value = Number(map.get(item));
        if (value) {
          newCount += value;
        }
      });
      count.value = newCount;
    };
    const { addShopCar, addStorge, subStorge } = useShopCar();
    const guid = localStorage.getItem("guid");
    function getCount(newValue: any[]) {
      let newCount = 0;
      newValue.forEach((item: any) => {
        const value = Number(map.get(item));
        if (value) {
          newCount += value;
        }
      });
      if (
        list.value &&
        list.value.length &&
        newValue.length == list.value.length
      ) {
        checked.value = true;
      } else {
        checked.value = false;
      }
      count.value = newCount;
      let singPrice = 0
      if (newValue && list.value) {
        list.value.forEach((item: any) => {
          singPrice += item.qty * item.price
        });
        totalPrice.value = singPrice
      }
    }
    function getData(stopRefresh?: boolean) {
      checked1.length = 0;
      axios
        .get("/M/ShoppingCart", {
          params: {
            cartGUID: guid,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj.list;
            biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
            postageFee.value = res.data.obj.postageFee;
            let startPrice = 0
            data.length>0 && data.forEach((item: any) => {
              startPrice += item.qty * item.price
            });
            totalPrice.value = startPrice
            list.value = data;
            if (!stopRefresh) {
              data.forEach((item: any) => {
                map.set(item.pidAndLotId, item.qty);
                checked1.push(item.pidAndLotId);
              });
            } else {
              checked1.length = 0;
              data.forEach((item: any) => {
                map.set(item.pidAndLotId, item.qty);
                checked1.push(item.pidAndLotId);
              });
            }
            getCount(checked1);
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
          loading.value = false;
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
          loading.value = false;
        });
    }
    getData();

    watch(
      () => {
        return checked1;
      },
      (newValue) => {
        getCount(newValue);
      }
    );

    function onAdd(id: string, index: number) {
      (list.value as any)[index].qty += 1;
      // count.value += 1;
      map.set(id, (list.value as any)[index].qty);
      getCount(checked1);
      addStorge(id, (list.value as any)[index].qty);
    }
    function onSub(id: string, index: number) {
      if ((list.value as any)[index].qty <= 1) {
        Toast({
          type: "warning",
          title: "More than 1 item.",
        });
        return false;
      }
      (list.value as any)[index].qty -= 1;
      // count.value -= 1;
      map.set(id, (list.value as any)[index].qty);
      getCount(checked1);
      addStorge(id, (list.value as any)[index].qty);
    }
    const loadOption = {
      text: "loading...",
      color: "#df0024",
      textColor: "#df0024",
    };

    function onDelete() {
      if (!checked1.length) {
        Toast({
          type: "warning",
          title: " Select item to delete.",
        });

        return false;
      }
      Modal({
        title: "System prompt",
        content: "Are you sure you want to delete?",
        onConfirm: () => {
          const data = qs.stringify({
            ids: checked1.join(","),
          });
          axios
            .post("/M/Server/DeleteCartItem", data, {
              params: {
                cartGUID: guid,
              },
            })
            .then((res) => {
              if (res.data.success) {
                totalPrice.value = 0
                getData(true);
                getCartInfo()
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
              loading.value = false;
            })
            .catch((err) => {
              Toast({
                type: "error",
                title: err.message,
              });
              loading.value = false;
            });
        },
      });
    }

    function onSubmit() {
      if (!count.value) {
        Toast({
          type: "warning",
          // title: "请选择要结算的商品！",
          title: " Select items to checkout.",
        });
        return false;
      }
      axios
        .post(
          "/M/Server/DoCheckoutNew",
          qs.stringify({
            idstr: checked1.join(","),
          }),
          {
            params: {
              validate: true,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            Modal({
              title: "System prompt",
              content: "Dear user, your order has been submitted successfully!",
              closeText: "close",
              onClose: () => {
                getCartInfo()
                router.push({
                  name: "Order",
                  query: {
                    type: 0,
                  },
                });
              },
            });
          } else {
            if(res.data.msg=='NOAddress'){
              Modal({
                title: "System prompt",
                content: "No delivery address, please add the delivery address.",
                closeText: "close",
                confirmText:"to add",
                onConfirm: () => {
                  getCartInfo()
                  router.push({
                    name: "addAddress",
                  });
                },
              });
            }else{
              Toast({
                type: "error",
                title: res.data.msg,
                duration:3000
              });
            }
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }
    function changeEdit(id: string, item: any, index: any) {
      if (item<=0) {
        Toast({
          type: "error",
          title: 'More than 1 item.',
          onClose: () => {
            location.reload();
          },
        });
        return false
      }
      addStorge(id, item);
    }
    return {
      list,
      checked1,
      checked,
      changeBox,
      changeBoxGroup,
      onAdd,
      onSub,
      count,
      loadOption,
      loading,
      onDelete,
      onSubmit,
      totalPrice,
      userInfo,
      delivery,
      biaowuPriceOpen,
      postageFee,
      changeEdit,

    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 2.933rem;
}

.car-li {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-top: 1px solid #eee;
  overflow: hidden;
  padding: 0.6rem 0.267rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.car-li .checkbox {
  position: absolute;
  width: 0.533rem;
  height: 0.533rem;
  left: 0.267rem;
  top: 50%;
  margin-top: -0.267rem;
  z-index: 10;
}
.car-li .checkbox .car-check {
  position: absolute;

  width: 0.533rem;
  height: 0.533rem;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
}
.car-li .checkbox img {
  position: absolute;
  width: 0.533rem;
  height: 0.533rem;
  vertical-align: top;
  left: 0;
  top: 0;
  z-index: 5;
  display: none;
}
.car-li .checkbox::after {
  content: "";
  position: absolute;
  width: 0.533rem;
  height: 0.533rem;
  left: 0;
  top: 0;
  border: 1px solid #ccc;
  border-radius: 0.067rem;
  box-sizing: border-box;
}
.product-wapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-pic {
  margin: 0 15px;
  width: 2rem;
  height: 2rem;
  a {
    display: block;
  }
  img {
    // width: 2rem;
    // height: 2rem;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.product-info {
  padding-right: 0.267rem;
  .product-no {
    // font-size: 0.347rem;
    font-size: 12px;
    color: #999;
    width: 16em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .product-name {
    width: 16em;
    display: block;
    font-size: 0.373rem;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding-right: 15px;
    position: relative;
    top: -1px;
  }
  .poroduct-num {
    // margin-top: 0.133rem;
    line-height: 23px;
  }
  .input {
    display: inline-block;
    border: 1px solid #eee;
    // height: 0.667rem;
    height: 23px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  input {
    width: 1.1rem;
    height: 100%;
    outline: none;
    border: none;
    vertical-align: top;
    box-sizing: border-box;
    color: #666;
    text-align: center;
    font-size: 13px;
  }
  input:disabled {
    background-color: transparent;
  }
  .sub {
    display: inline-block;
    position: relative;
    // left: 0.213rem;
    width: 0.667rem;
    // height: 0.667rem;
    height: 23px;
    text-align: center;
    // line-height: 0.667rem;
    vertical-align: middle;
    // background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d13.png")
    //   no-repeat center center;
    background: #f4f4f4 url("../assets/img/shopCar/d13.png")
      no-repeat center center;
  }
  .add {
    display: inline-block;
    position: relative;
    // left: -0.213rem;
    width: 0.667rem;
    // height: 0.667rem;
    height: 23px;
    text-align: center;
    // line-height: 0.667rem;
    vertical-align: middle;
    // background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d14.png")
    //   no-repeat center center;
    background: #f4f4f4 url("../assets/img/shopCar/d14.png")
      no-repeat center center;
    background-size: 0.267rem 0.267rem;
  }
}

.dashboard {
  position: fixed;
  bottom: 1.333rem;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 1.333rem;
  text-align: center;
  font-size: 0.373rem;
  border-top: 0.027rem solid #f4f4f4;
  line-height: 1.333rem;
  color: #666;
  z-index: 1;
}

.dashboard .dashboard-checkbox {
  position: absolute;
  top: 50%;
  margin-top: -0.267rem;
  padding-left: 0.267rem;
  line-height: 0.533rem;
  height: 0.533rem;
}
.dashboard .dashboard-checkbox input {
  width: 0.533rem;
  height: 0.533rem;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 10;
  position: absolute;
}
.dashboard .dashboard-checkbox img {
  position: absolute;
  width: 0.533rem;
  height: 0.533rem;
  vertical-align: top;
  left: 0;
  top: 0;
  z-index: 5;
  display: none;
}

.del {
  display: inline-block;
  color: #666;
  margin-left: 0.667rem;
}
.pay {
  position: absolute;
  right: 0.267rem;
  top: 0;
  // width: 2.667rem;
  padding: 0 8px;
  height: 0.933rem;
  background-color: #d64b4b;
  color: #fff;
  border-radius: 0.933rem;
  text-align: center;
  line-height: 0.933rem;
  top: 50%;
  margin-top: -0.467rem;
}

.total-price {
  // position: relative;
  // left: 28px;
  position: absolute;
  width: 110px;
  right: 110px;
  span {
    color: #d64b4b;
  }
}

.extra-price {
  // position: relative;
  // left: 28px;
  // height: 100%;
  position: absolute;
  width: 110px;
  right: 110px;
  line-height: 27px;
  div {
    height: 20px;
  }
  .price {
    font-size: 12px;
    span {
      color: #d64b4b;
      font-size: 14px;
    }
  }
  .extra {
    font-size: 12px;
    color: #d64b4b;
  }
}

.single-price {
  font-size: 16px;
  color: #666;
  margin-left: 20px;
  color: #d64b4b;
}
</style>
